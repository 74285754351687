import { useEffect } from "react";
import { Link } from "react-router-dom";
import LogRocket from 'logrocket';
import './App.css';

export default function ContactPage() {

  useEffect(() => {
    console.log('View Contact');
    LogRocket.log('contact', { serial: '123456' });
  }, []);

  return (
    <div id="contact-page" className="app">
      <h1>Contact</h1>
      <Link to={`/`} className="back">back</Link>
    </div>
  );
}