import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import './index.css';
import App from './App';
import ErrorPage from './error-page';
import AboutPage from './about-page';
import ContactPage from './contact-page';

LogRocket.init('vfycte/log-rocket-react', {
  release: '0.1.0',
});
LogRocket.identify('xxx-yyy-zzz', {
  kioskNumber: '001'
});
setupLogRocketReact(LogRocket);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "contact",
    element: <ContactPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
